section.photo-gallery{
    margin-top:375px;
   
    .alice-carousel li.alice-carousel__stage-item {
        height:600px;
    }
    .alice-carousel__prev-btn {
        text-align: center!important;
    }
   
    .gallery-images {
       table ul li {
            margin-bottom:15px;
        }
    }
}
.gallery-images {
    table ul li {
         margin-bottom:15px;
         span {
             font-weight: bold;
         }
     }
 }
 #images {
     #date{
        display:none;
     }
 }
 .clearfix {
    overflow: auto;
  }
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
  .gallery-image {
      margin-bottom: 35px;
  }
  .alice-carousel__dots {
      margin-top: 0;
  }
  .thumbnails {
      display: table;
      margin:0 auto;
      li {
          display: table-cell;
          padding-right:15px;
      }
  }
  .carousel .thumbs-wrapper {
    margin: 20px;
 overflow: visible!important;
 display: flex;
    justify-content: center;
}

.thumb {
    display:inline-block;
    margin-right:15px;
    margin-top:15px;
    img:hover {
      cursor: pointer;
    }
}
.galleryContainer {
    min-height:450px;
    max-height: 745px;
    overflow: hidden;
    position:relative;
 ul {
    display: flex;
   
 }
    .slide {
        min-width: 100%;
        margin: 0;
        position: relative;
        text-align: center;
        img {
            border:1px solid #000;
        }
    }
}
.legend {
    background:rgba(0,0,0,1);
    padding:10px;
    color:#FFF;
    position: absolute;
    bottom:50%;
    width:80%;
    left:10%;
    font: 400 12px/14px 'Montserrat', sans-serif;
    border-radius: 5px;
}
.gallery-left {
    
}
.gallery-right {

}
.vail {
    position: absolute;
    z-index: 999;
    background:#FFF;
    width:100%;
    height:100%;
    display:none;
}
.fade-in {
    animation: fadeIn ease 30s;
    -webkit-animation: fadeIn ease 30s;
    -moz-animation: fadeIn ease 30s;
    -o-animation: fadeIn ease 30s;
    -ms-animation: fadeIn ease 30s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
}
.fade-out {
    animation: fadeOut ease 8s;
    -webkit-animation: fadeOut ease 8s;
    -moz-animation: fadeOut ease 8s;
    -o-animation: fadeOut ease 8s;
    -ms-animation: fadeOut ease 8s;
  }
  @keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-moz-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-o-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-ms-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
  }
}
@media screen and (min-width: 320px) and (max-width: 778px){/*Large*/
    .galleryContainer {
        max-height: 294px;
    }

}
@media screen and (min-width: 779px) and (max-width: 991px){/*Large*/
    .galleryContainer {
        max-height: 441px;
    }

}